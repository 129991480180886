import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import Subscription from './subTabs/Subscription';
import Configuration from './subTabs/Configuration';

import { ACCOUNT_SUB_TABS } from '../../../helpers/constants';

const Account = () => {
  return (
    <div>
      <Tabs
        defaultActiveKey={ACCOUNT_SUB_TABS.FIRST_TAB.EVENT_KEY}
        className="SubTabs m-2"
        fill
      >
        <Tab
          eventKey={ACCOUNT_SUB_TABS.FIRST_TAB.EVENT_KEY}
          title={ACCOUNT_SUB_TABS.FIRST_TAB.TITLE}
        >
          <Subscription />
        </Tab>
        <Tab
          eventKey={ACCOUNT_SUB_TABS.SECOND_TAB.EVENT_KEY}
          title={ACCOUNT_SUB_TABS.SECOND_TAB.TITLE}
        >
          <Configuration />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Account;
