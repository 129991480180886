import React, { useState, useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import FormControl from 'react-bootstrap/FormControl';

import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import SearchIcon from '@heroicons/react/24/outline/MagnifyingGlassIcon';

import { SettingsContext } from '../helpers/context/settingsContext';
import { SchemaContext } from '../helpers/context/schemaContext';

const SearchBox = ({ form, type }) => {
  const [settings, setSettings] = useContext(SettingsContext);
  const [isInputValue, setIsInputValue] = useState('');
  const [schemas, setSchemas] = useContext(SchemaContext);

  const { register, setValue, getValues } = useFormContext();
  const [breadcrumb, setBreadcrumb] = useState(['All Categories']);
  const [selectees, setSelectees] = useState([]);
  const [selectedClass, setSelectedClass] = useState({
    father: 'All Categories',
    child: '',
  });

  const deleteRightElements = (element) => {
    const index = breadcrumb.indexOf(element);

    if (index !== -1) {
      const newArray = breadcrumb.slice(0, index + 1);
      setBreadcrumb(newArray);
    }
  };

  useEffect(() => {
    if (breadcrumb.length === 1) {
      setSelectees({
        'All Categories': [
          'Contact Properties',
          'Company Properties',
          'Deal Properties',
          'Ticket Properties',
        ],
      });
    }
  }, [breadcrumb]);

  function moldData(data) {
    const result = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        result[key] = data[key].map((child) => child.name);
      }
    }
    return result;
  }

  function filterNames(data, input) {
    const filteredData = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const filteredNames = data[key].filter((name) =>
          name.toLowerCase().includes(input.toLowerCase())
        );
        filteredData[key] = filteredNames;
      }
    }
    return filteredData;
  }

  const getSelectorKey = (e) => {
    switch (e) {
      case 'Contact Properties':
        return 'contacts';
      case 'Company Properties':
        return 'companies';
      case 'Deal Properties':
        return 'deals';
      case 'Ticket Properties':
        return 'tickets';
    }
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setIsInputValue(value);
    setSelectees(filterNames(selectees, value));
    if (value === '') {
      if (breadcrumb[breadcrumb.length - 1] === 'All Categories') {
        setSelectees({
          'All Categories': [
            'Contact Properties',
            'Company Properties',
            'Deal Properties',
            'Ticket Properties',
          ],
        });
      } else {
        setSelectees(
          moldData(schemas[getSelectorKey(breadcrumb[breadcrumb.length - 1])])
        );
      }
    }
  };

  const setSelector = () => {
    if (selectedClass.father === 'All Categories') {
      setSelectees({
        'All Categories': [
          'Contact Properties',
          'Company Properties',
          'Deal Properties',
          'Ticket Properties',
        ],
      });
    }
    if (selectedClass.child === 'Contact Properties') {
      setSelectees(moldData(schemas.contacts));
      setValue(`edProp.${form.index}.object`, 'contact');
      setBreadcrumb((state) => [...state, selectedClass.child]);
    }
    if (selectedClass.child === 'Company Properties') {
      setSelectees(moldData(schemas.companies));
      setValue(`edProp.${form.index}.object`, 'company');
      setBreadcrumb((state) => [...state, selectedClass.child]);
    }
    if (selectedClass.child === 'Deal Properties') {
      setSelectees(moldData(schemas.deals));
      setValue(`edProp.${form.index}.object`, 'deals');
      setBreadcrumb((state) => [...state, selectedClass.child]);
    }
    if (selectedClass.child === 'Ticket Properties') {
      setSelectees(moldData(schemas.tickets));
      setValue(`edProp.${form.index}.object`, 'ticket');
      setBreadcrumb((state) => [...state, selectedClass.child]);
    }
    if (type === 'IF') {
      setValue(`edProp.${form.index}.groupName`, selectedClass.father);
      setValue(`edProp.${form.index}.property`, selectedClass.child);
    }
    if (type === 'ENABLE') {
      setValue(`edProp.${form.index}.groupNameEnable`, selectedClass.father);
      setValue(`edProp.${form.index}.enable`, selectedClass.child);
    }
  };

  useEffect(() => {
    setSelector();
  }, [selectedClass]);

  return (
    <>
      <Breadcrumb className="breadcrumb">
        {breadcrumb.map((item) => (
          <Breadcrumb.Item key={item} onClick={() => deleteRightElements(item)}>
            {item}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <InputGroup>
        <FormControl
          type="text"
          placeholder="Search on filter categories"
          onChange={handleChange}
          value={isInputValue}
          className="edit-input-disabled"
        />
        <div className="icon-section">
          <SearchIcon className="search-icon" />
        </div>
        <div className="search-container">
          {Object.keys(selectees).map((item) => (
            <div key={item}>
              <span className="select-search-group-header">{item}</span>
              <ul className="select-search-options">
                {selectees[item].length !== 0 &&
                  selectees[`${item}`].map((prop) => (
                    <li key={prop} className="select-search-row">
                      <button
                        className="select-search-option"
                        type="button"
                        onClick={() =>
                          setSelectedClass({
                            father: item,
                            child: prop,
                          })
                        }
                      >
                        {prop}
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
          ))}
        </div>
      </InputGroup>
    </>
  );
};

export default SearchBox;
