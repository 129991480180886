import React from 'react';
import ReactDOM from 'react-dom/client';
import Main from './views/Main';

import './index.css';
import './style.css';
import SettingsProvider from './helpers/context/settingsContext';
import SchemaProvider from './helpers/context/schemaContext';
import LoadingProvider from './helpers/context/loading';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <LoadingProvider>
      <SettingsProvider>
        <SchemaProvider>
          <Main />
        </SchemaProvider>
      </SettingsProvider>
    </LoadingProvider>
  </React.StrictMode>
);
