import React, { useState, useEffect, createContext, useContext } from 'react';
import { LoadingContext } from './loading';
import { ACTION, GET } from '../api';
import { dataModeler } from '../utils';

export const SchemaContext = createContext([]);

const Store = ({ children }) => {
  const [schemas, setSchemas] = useState({});
  const [loading, setLoading] = useContext(LoadingContext);

  const getAllSchemas = async () => {
    const companiesSchema = await GET(ACTION.PROPS_COMPANIES);
    const contactsSchema = await GET(ACTION.PROPS_CONTACTS);
    const dealsSchema = await GET(ACTION.PROPS_DEALS);
    const ticketsSchema = await GET(ACTION.PROPS_TICKETS);

    return {
      companies: dataModeler(companiesSchema?.schema?.properties),
      contacts: dataModeler(contactsSchema?.schema?.properties),
      deals: dataModeler(dealsSchema?.schema?.properties),
      tickets: dataModeler(ticketsSchema?.schema?.properties),
    };
  };
  useEffect(() => {
    setLoading(true);
    getAllSchemas()
      .then((data) => {
        setSchemas(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <SchemaContext.Provider value={[schemas, setSchemas]}>
      {children}
    </SchemaContext.Provider>
  );
};

export default Store;
