import React from 'react';
import { useFormContext } from 'react-hook-form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import Contacts from './subTabs/Contacts';
import Companies from './subTabs/Companies';

import { ENABLE_PROPS_SUB_TABS } from '../../../helpers/constants';
import Deals from './subTabs/Deals';
import Tickets from './subTabs/Tickets';
import CustomObjects from './subTabs/CustomObjects';
import PreviousEngagement from './subTabs/PreviousEngagement';

const EnableProperties = () => {
  const {
    formState: { isDirty },
  } = useFormContext();

  const onTabSelect = () => {
    if (isDirty) {
      console.log('SPORCO');
    }
  };

  return (
    <div>
      <Tabs
        defaultActiveKey={ENABLE_PROPS_SUB_TABS.FIRST_TAB.EVENT_KEY}
        className="SubTabs m-2"
        fill
        onSelect={onTabSelect}
      >
        <Tab
          eventKey={ENABLE_PROPS_SUB_TABS.FIRST_TAB.EVENT_KEY}
          title={ENABLE_PROPS_SUB_TABS.FIRST_TAB.TITLE}
        >
          <Contacts />
        </Tab>
        <Tab
          eventKey={ENABLE_PROPS_SUB_TABS.SECOND_TAB.EVENT_KEY}
          title={ENABLE_PROPS_SUB_TABS.SECOND_TAB.TITLE}
        >
          <Companies />
        </Tab>
        <Tab
          eventKey={ENABLE_PROPS_SUB_TABS.THIRD_TAB.EVENT_KEY}
          title={ENABLE_PROPS_SUB_TABS.THIRD_TAB.TITLE}
        >
          <Deals />
        </Tab>
        <Tab
          eventKey={ENABLE_PROPS_SUB_TABS.FOURTH_TAB.EVENT_KEY}
          title={ENABLE_PROPS_SUB_TABS.FOURTH_TAB.TITLE}
        >
          <Tickets />
        </Tab>
        <Tab
          eventKey={ENABLE_PROPS_SUB_TABS.FIFTH_TAB.EVENT_KEY}
          title={ENABLE_PROPS_SUB_TABS.FIFTH_TAB.TITLE}
        >
          <CustomObjects />
        </Tab>
        <Tab
          eventKey={ENABLE_PROPS_SUB_TABS.SIXTH_TAB.EVENT_KEY}
          title={ENABLE_PROPS_SUB_TABS.SIXTH_TAB.TITLE}
        >
          <PreviousEngagement />
        </Tab>
      </Tabs>
    </div>
  );
};

export default EnableProperties;
