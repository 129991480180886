import React from 'react';
import { ContactSwitcher } from './components/ContactSwitcher';

const list = [
  {
    name: 'test',
    users: [
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
    ],
  },
  {
    name: 'test',
    users: [
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
    ],
  },
  {
    name: 'test',
    users: [
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
    ],
  },
  {
    name: 'test',
    users: [
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
    ],
  },
  {
    name: 'test',
    users: [
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
    ],
  },
  {
    name: 'test',
    users: [
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
      {
        name: 'Mohamed Bolbol',
        value: 'mohamed.bolbol@exelab.com',
      },
    ],
  },
];

const UserMapping = () => {
  return (
    <>
      <ContactSwitcher>
        <ContactSwitcher.Group
          withIcon
          list={list}
          title="HubSpot User contact information"
          name="hubspot"
          image="./static/images/hubspot-logo.svg"
        />
        <ContactSwitcher.Group
          list={list}
          title="Twilio Agent contact information"
          name="twilio"
          image="./static/images/twilio-logo.svg"
        />
      </ContactSwitcher>
    </>
  );
};

export default UserMapping;
