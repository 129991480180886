import React, { useState, useEffect, createContext, useContext } from 'react';
import { LoadingContext } from './loading';
import { GET, ACTION } from '../api';

export const SettingsContext = createContext([]);

const Store = ({ children }) => {
  const [settings, setSettings] = useState({
    properties: {},
    engagements: {},
    editableProperties: [],
  });
  const [loading, setLoading] = useContext(LoadingContext);

  useEffect(() => {
    setLoading(true);
    GET(ACTION.SETTINGS)
      .then((data) => {
        setSettings({
          properties: data.properties,
          engagements: data.engagements,
          editableProperties: data.editableProperties,
        });
      })
      .catch((error) => {
        console.log(error);
        setSettings({
          properties: {},
          engagements: {},
          editableProperties: [],
        });
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <SettingsContext.Provider value={[settings, setSettings]}>
      {children}
    </SettingsContext.Provider>
  );
};

export default Store;
