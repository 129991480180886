import axios from 'axios';
// baseurl
const baseurl = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: baseurl,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export const ACTION = {
  ACCOUNT: `/api/hsapp/iframe/account`,
  SETTINGS: `/api/hsapp/iframe/settings`,
  PROPS_COMPANIES: '/api/hsapp/iframe/properties/companies',
  PROPS_CONTACTS: '/api/hsapp/iframe/properties/contacts',
  PROPS_DEALS: '/api/hsapp/iframe/properties/deals',
  PROPS_TICKETS: '/api/hsapp/iframe/properties/tickets',
  POST_PROPS: '/api/hsapp/iframe/update/properties',
  POST_ENGAGEMENTS: '/api/hsapp/iframe/update/engagements',
  POST_EDITPROPS: '/api/hsapp/iframe/update/editable-properties',
};

export const GET = async (apiUrl) => {
  const response = await api.get(apiUrl, {
    params: { portalId: 5330991 },
  });
  return response.data;
};

export const POST = async (apiUrl, body) => {
  try {
    const response = await api.post(apiUrl, body, {
      params: { portalId: 5330991 },
    });
    return response.data;
  } catch (error) {
    console.log('ERROR IN POST: ', error);
  }
};

export const getAccount = async () => {
  const response = await api.get(ACTION.ACCOUNT);
  return response.data;
};
