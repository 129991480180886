import { Fragment } from 'react';
import Form from 'react-bootstrap/Form';
import './module.style.css';

export const ContactSwitcher = ({ children }) => {
  return <div className="ContactSwitcher">{children && children}</div>;
};

export const Group = ({ image, name, title, list, withIcon }) => {
  return (
    <div className="ContactSwitcher_group">
      {image && <img src={image} alt="image_alt" />}
      {title && <p>{title}</p>}
      {list &&
        list.map(({ users }, index) => {
          return (
            <div
              className={`ContactSwitcher_select ${withIcon ? 'withIcon' : ''}`}
              key={`select--${index}`}
            >
              <Form.Select>
                <option>Select option</option>
                {users.map(({ name, value }, i) => {
                  return (
                    <option
                      key={`${name}--${i}`}
                      value={value}
                    >{`${name} (${value})`}</option>
                  );
                })}
              </Form.Select>
            </div>
          );
        })}
    </div>
  );
};

ContactSwitcher.Group = Group;
