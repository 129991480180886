import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import EnableProperties from './EnableProperties';
import EditableProperties from './EditableProperties';
import UserMapping from './UserMapping';
import Account from './Account';

import { MAIN_TABS } from '../../helpers/constants';

const TabsSection = ({ setSelectedTab }) => {
  const onTabSelect = (key) => {
    setSelectedTab(key);
  };
  return (
    <div className="tabs-section">
      <Tabs
        defaultActiveKey={MAIN_TABS.FIRST_TAB.EVENT_KEY}
        className="TabsSection m-2"
        fill
        onSelect={onTabSelect}
      >
        <Tab
          eventKey={MAIN_TABS.FIRST_TAB.EVENT_KEY}
          title={MAIN_TABS.FIRST_TAB.TITLE}
        >
          <EnableProperties />
        </Tab>
        <Tab
          eventKey={MAIN_TABS.SECOND_TAB.EVENT_KEY}
          title={MAIN_TABS.SECOND_TAB.TITLE}
        >
          <EditableProperties />
        </Tab>
        <Tab
          eventKey={MAIN_TABS.THIRD_TAB.EVENT_KEY}
          title={MAIN_TABS.THIRD_TAB.TITLE}
        >
          <UserMapping />
        </Tab>
        <Tab
          eventKey={MAIN_TABS.FOURTH_TAB.EVENT_KEY}
          title={MAIN_TABS.FOURTH_TAB.TITLE}
        >
          <Account />
        </Tab>
      </Tabs>
    </div>
  );
};

export default TabsSection;
