import React, { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { getAccount } from '../../../../helpers/api';

const Configuration = () => {
  const [account, setAccount] = useState({
    auth_token: '',
    account_sid: '',
    phone_number: '',
  });

  useEffect(() => {
    getAccount().then((data) => {
      const { auth_token, account_sid, phone_number } = data.account;
      setAccount({
        auth_token,
        account_sid,
        phone_number,
      });
    });
  }, []);

  return (
    <div className="configuration">
      <Container>
        <div className="account-header">
          <h2>I tuoi dati di configurazione</h2>
        </div>
        <Table size="sm">
          <tbody>
            <tr>
              <td>AccountSid</td>
              <td>{account.account_sid}</td>
            </tr>
            <tr>
              <td>AuthToken</td>
              <td>{account.auth_token}</td>
            </tr>
            <tr>
              <td>Telefono</td>
              <td>{account.phone_number}</td>
            </tr>
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default Configuration;
