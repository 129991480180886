import React, { useState, useEffect, useContext } from 'react';
import NoProps from './subviews/NoProps';
import PropsList from './subviews/PropsList';
// Contexts
import { SettingsContext } from '../../../helpers/context/settingsContext';

const EditableProperties = () => {
  const [settings, setSettings] = useContext(SettingsContext);
  const [form, setForm] = useState({
    visible: false,
    index: '',
    type: 'create',
  });

  useEffect(() => {
    if (settings.editableProperties.length === 0) {
      setForm({
        visible: false,
        index: '',
        type: 'create',
      });
    } else {
      setForm({
        visible: false,
        index: '',
        type: 'modify',
      });
    }
  }, [settings]);
  return (
    <>
      {settings.editableProperties.length === 0 ? (
        <NoProps form={form} setForm={setForm} />
      ) : (
        <PropsList form={form} setForm={setForm} />
      )}
    </>
  );
};

export default EditableProperties;
