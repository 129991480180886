export const MAIN_TABS = {
  FIRST_TAB: {
    EVENT_KEY: 'enableProperties',
    TITLE: 'Enable Properties',
  },
  SECOND_TAB: {
    EVENT_KEY: 'editableProperties',
    TITLE: 'Enable Editable Properties',
  },
  THIRD_TAB: {
    EVENT_KEY: 'userMapping',
    TITLE: 'User Mapping',
  },
  FOURTH_TAB: {
    EVENT_KEY: 'account',
    TITLE: 'Account',
  },
};

export const ENABLE_PROPS_SUB_TABS = {
  FIRST_TAB: {
    EVENT_KEY: 'contacts',
    TITLE: 'Contacts',
  },
  SECOND_TAB: {
    EVENT_KEY: 'company',
    TITLE: 'Company',
  },
  THIRD_TAB: {
    EVENT_KEY: 'deals',
    TITLE: 'Deals',
  },
  FOURTH_TAB: {
    EVENT_KEY: 'tickets',
    TITLE: 'Tickets',
  },
  FIFTH_TAB: {
    EVENT_KEY: 'customObjects',
    TITLE: 'Custom Objects',
  },
  SIXTH_TAB: {
    EVENT_KEY: 'prevEngagement',
    TITLE: 'Previous Engagement',
  },
};

export const ACCOUNT_SUB_TABS = {
  FIRST_TAB: {
    EVENT_KEY: 'subscription',
    TITLE: 'Abbonamento',
  },
  SECOND_TAB: {
    EVENT_KEY: 'configuration',
    TITLE: 'Configurazione account',
  },
};

export const TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJwcmFiaGRlZXAubXVsdGFuaUBleGVsYWIuY29tIiwicG9ydGFsSWQiOiI1MzMwOTkxIiwiaWF0IjoxNjgyNjg4ODg2LCJleHAiOjE2ODI2OTE1ODZ9.072ak2qtFDHIUqOZE1NY9xPGnHeDjPd2s_XyXPZ13xQ';
