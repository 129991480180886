import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from './Form';

const NoProps = ({ form, setForm }) => {
  return (
    <>
      {form.visible === false ? (
        <div className="center">
          <div className="div-center">
            <p>Non hai ancora properties editabili</p>
            <Button
              className="custom-button"
              onClick={() =>
                setForm({
                  visible: true,
                  index: 0,
                  type: 'create',
                })
              }
            >
              + Add
            </Button>
          </div>
        </div>
      ) : (
        <Form form={form} setForm={setForm} />
      )}
    </>
  );
};

export default NoProps;
