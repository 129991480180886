import React from 'react';

import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

const Subscription = () => {
  return (
    <div className="subscription">
      <Form.Group>
        <Container>
          <Row>
            <Col>
              <div className="account-header">
                <h2>Il tuo abbonamento</h2>
                <Badge bg="primary">ATTIVO</Badge>
              </div>
              <Table size="sm">
                <tbody>
                  <tr>
                    <td>Prezzo</td>
                    <td>39€/mese</td>
                  </tr>
                  <tr>
                    <td>Attivato il</td>
                    <td>04/02/2023</td>
                  </tr>
                  <tr>
                    <td>Prossimo rinnovo</td>
                    <td>04/10/2023</td>
                  </tr>
                  <tr>
                    <td>Rinnovo automatico</td>
                    <td>attivo</td>
                  </tr>
                </tbody>
              </Table>
              <Button type="submit">Rinnova ora</Button>
            </Col>
            <Col>
              <div className="account-header">
                <h2>I tuoi Add-ons</h2>
              </div>
              <div className="subscription-box">
                <dl>
                  <dt>Nome add-on</dt>
                  <dd>
                    <Badge bg="primary">ATTIVO</Badge>
                  </dd>
                </dl>
                <dl>
                  <dt>Attivato il</dt>
                  <dd>04/02/2023</dd>
                </dl>
              </div>
              <p>Vuoi ancora più potenzialità?</p>
              <a href="#">Scopri gli altri plugin</a>
            </Col>
          </Row>
        </Container>
      </Form.Group>
    </div>
  );
};

export default Subscription;
