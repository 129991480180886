// Functional Libs
import React, { useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
// Component Libs
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// Context
import { SettingsContext } from '../../../../helpers/context/settingsContext';

const Companies = () => {
  const [settings, setSettings] = useContext(SettingsContext);
  const { register, setValue } = useFormContext();

  useEffect(() => {
    /**
     * Sets data in the RHF register
     * */
    const setDefaultValues = () => {
      setValue('engagements.history', settings?.engagements?.history);
    };
    setDefaultValues();
  }, [settings, setValue]);

  return (
    <Form.Group>
      <Container className="view">
        <Row>
          <Col>
            <div className="side-col">
              <p className="side-title">View Previous engagement in Twilio</p>
              <Form.Check
                className="side-switch"
                type="switch"
                id="custom-switch"
                key="engagements.history"
                {...register('engagements.history')}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Form.Group>
  );
};

export default Companies;
