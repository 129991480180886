import React from 'react';
import { useFormContext } from 'react-hook-form';

import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const CustomObjects = () => {
  const { register, getValues } = useFormContext();
  const items = [
    {
      title: 'Gruppo di properties numero 1',
      id: 'a',
      checked: false,
      label: 'sub-prop 1',
    },
    {
      title: 'Gruppo di properties numero 2',
      id: 'b',
      checked: false,
      label: 'sub-prop 2',
    },
    {
      title: 'Gruppo di properties numero 3',
      id: 'c',
      checked: false,
      label: 'sub-prop 3',
    },
    {
      title: 'Gruppo di properties numero 4',
      id: 'd',
      checked: false,
      label: 'sub-prop 4',
    },
    {
      title: 'Gruppo di properties numero 5',
      id: 'e',
      checked: false,
      label: 'sub-prop 5',
    },
    {
      title: 'Gruppo di properties numero 6',
      id: 'f',
      checked: false,
      label: 'sub-prop 6',
    },
    {
      title: 'Gruppo di properties numero 7',
      id: 'g',
      checked: false,
      label: 'sub-prop 7',
    },
    {
      title: 'Gruppo di properties numero 8',
      id: 'h',
      checked: false,
      label: 'sub-prop 8',
    },
    {
      title: 'Gruppo di properties numero 9',
      id: 'i',
      checked: false,
      label: 'sub-prop 9',
    },
  ];
  const CheckDropdownItem = React.forwardRef(
    ({ children, id, checked, onChange }, ref) => {
      return (
        <Form.Group ref={ref} className="dropdown-item mb-0" controlId={id}>
          <Form.Check
            type="checkbox"
            label={children}
            checked={checked}
            onChange={onChange && onChange.bind(onChange, id)}
          />
        </Form.Group>
      );
    }
  );

  return (
    <Form.Group>
      <Container className="view">
        <Row>
          <Col>
            <Row>
              <div className="side-col">
                <p className="side-title">
                  View Custom object 1 properties in Twilio
                </p>
                <Form.Check
                  className="side-switch"
                  type="switch"
                  id="custom-switch-1"
                />
              </div>
            </Row>
            <Row>
              <div className="side-col">
                <p className="side-title">
                  View Custom object 2 properties in Twilio
                </p>
                <Form.Check
                  className="side-switch"
                  type="switch"
                  id="custom-switch-2"
                />
              </div>
            </Row>
            <Row>
              <div className="side-col">
                <p className="side-title">
                  View Custom object 3 properties in Twilio
                </p>
                <Form.Check
                  className="side-switch"
                  type="switch"
                  id="custom-switch-3"
                />
              </div>
            </Row>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </Form.Group>
  );
};

export default CustomObjects;
