import React, { useState, useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import EditIcon from '@heroicons/react/24/outline/PencilIcon';
import DeleteIcon from '@heroicons/react/24/outline/TrashIcon';
import { SettingsContext } from '../../../../helpers/context/settingsContext';
import { SchemaContext } from '../../../../helpers/context/schemaContext';
import Form from './Form';

const PropsList = ({ form, setForm }) => {
  const [settings, setSettings] = useContext(SettingsContext);
  const [schemas, setSchemas] = useContext(SchemaContext);
  const { register, setValue, getValues } = useFormContext();

  const findGroupName = (objectType, property) => {
    // find schema
    let instance;
    switch (objectType) {
      case 'contact':
        instance = schemas.contacts || [];
      case 'company':
        instance = schemas.companies || [];
      case 'deal':
        instance = schemas.deals || [];
      case 'ticket':
        instance = schemas.tickets || [];
    }
    // find group name
    let groupName;
    for (let i = 0; i < instance.length; i++) {
      const has = instance[i].includes(property);
      if (has) {
        groupName = Object.keys([instance[i]]);
      }
    }
    return groupName;
  };

  const setDefaultValues = () => {
    for (let i = 0; i < settings.editableProperties.length; i++) {
      setValue(`edProp.${i}.index`, i);
      setValue(`edProp.${i}.object`, settings.editableProperties[i].object);
      setValue(
        `edProp.${i}.groupName`,
        findGroupName(
          getValues(`edProp.${i}.object`),
          settings.editableProperties[i].property
        )
      );
      setValue(`edProp.${i}.property`, settings.editableProperties[i].property);
      setValue(
        `edProp.${i}.condition`,
        settings.editableProperties[i].condition
      );
      setValue(`edProp.${i}.value`, settings.editableProperties[i].value);
      setValue(
        `edProp.${i}.prop_value`,
        `${getValues(`edProp.${i}.condition`)} ${getValues(
          `edProp.${i}.value`
        )}`
      );
      setValue(`edProp.${i}.enable`, settings.editableProperties[i].enable);
    }
  };

  useEffect(() => {
    setDefaultValues();
  }, [settings]);

  const removeEditProp = (index) => {
    const arr = [...settings.editableProperties];
    if (index >= 0 && index < arr.length) {
      arr.splice(index, 1);
    }
    setSettings((state) => ({
      ...state,
      editableProperties: arr,
    }));
  };
  return (
    <>
      {form.visible === false ? (
        <div className="view">
          <Button
            className="custom-button float-right"
            onClick={() =>
              setForm({
                visible: true,
                index: settings.editableProperties.length,
                type: 'create',
              })
            }
          >
            + Add new
          </Button>
          {settings.editableProperties.map((property, index) => {
            return (
              <div className="editable-section" key={property.property}>
                <div className="editable-prop editable-prop-banner section-margin">
                  <p className="prop-title">{property.property}</p>
                  <div className="prop-buttons-section small-btns">
                    <a
                      className="icon-btn"
                      onClick={() =>
                        setForm({
                          visible: true,
                          index: index,
                          type: 'modify',
                        })
                      }
                    >
                      Edit <EditIcon className="icon" />
                    </a>
                    <a
                      className="icon-btn"
                      onClick={() => removeEditProp(index)}
                    >
                      Delete <DeleteIcon className="icon" />
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <Form form={form} setForm={setForm} />
      )}
    </>
  );
};

export default PropsList;
