// Functional Libs
import React, { useContext, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
// Component Libs
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
// Component Custom
import TabsSection from './tabs';
import Spinner from '../components/Spinner';
// Contexts
import { LoadingContext } from '../helpers/context/loading';
import { ACTION, POST } from '../helpers/api';
import { SettingsContext } from '../helpers/context/settingsContext';

const Main = () => {
  const formMethods = useForm();
  const [settings, setSettings] = useContext(SettingsContext);
  const [loading, setLoading] = useContext(LoadingContext);
  const [selectedTab, setSelectedTab] = useState('');

  function checkIfReadFromTwilioExists(arr) {
    return arr.includes('readFromTwilio');
  }

  function keyRemover(arr, toBeRemoved) {
    return arr.filter((item) => item !== toBeRemoved);
  }

  function getTrueProperties(data) {
    const trueProperties = [];

    for (const key in data) {
      if (typeof data[key] === 'object') {
        for (const prop in data[key]) {
          if (data[key][prop] === true) {
            trueProperties.push(prop);
          }
        }
      } else if (data[key] === true) {
        trueProperties.push(key);
      }
    }

    return keyRemover(trueProperties, 'total');
  }
  const moldData = async (data) => {
    let company = getTrueProperties(data.companies);
    const rft_company = checkIfReadFromTwilioExists(company);
    if (rft_company) {
      company = keyRemover(company, 'readFromTwilio');
    }

    let contact = getTrueProperties(data.contacts);
    const rft_contact = checkIfReadFromTwilioExists(contact);
    if (rft_contact) {
      contact = keyRemover(contact, 'readFromTwilio');
    }

    let deal = getTrueProperties(data.deals);
    const rft_deal = checkIfReadFromTwilioExists(deal);
    if (rft_deal) {
      deal = keyRemover(deal, 'readFromTwilio');
    }

    let ticket = getTrueProperties(data.ticket);
    const rft_ticket = checkIfReadFromTwilioExists(ticket);
    if (rft_ticket) {
      ticket = keyRemover(ticket, 'readFromTwilio');
    }
    const previousEngagement = data.engagements;

    setSettings((state) => ({
      ...state,
      properties: {
        companies: {
          readFromTwilio: rft_company,
          properties: company,
        },
        contacts: {
          readFromTwilio: rft_contact,
          properties: contact,
        },
        deals: {
          readFromTwilio: rft_deal,
          properties: deal,
        },
        tickets: {
          readFromTwilio: rft_ticket,
          properties: ticket,
        },
      },
      engagements: previousEngagement,
    }));
  };

  const onSubmit = async (data) => {
    await moldData(data);
    await POST(ACTION.POST_PROPS, settings.properties);
    await POST(ACTION.POST_ENGAGEMENTS, settings.engagements);
    await POST(ACTION.POST_EDITPROPS, settings.editableProperties);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    //formMethods.handleSubmit(() => onSubmit())}
  };

  return (
    <FormProvider {...formMethods}>
      {/* <div className="banner"/> */}
      <Form className="form-container" onSubmit={submitHandler}>
        <TabsSection setSelectedTab={setSelectedTab} />
        <Button
          className="save-button"
          type="submit"
          onClick={formMethods.handleSubmit(onSubmit)}
        >
          save
        </Button>
      </Form>
      {loading && <Spinner dimension={'20px'} />}
    </FormProvider>
  );
};

export default Main;
