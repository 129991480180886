// Functional Libs
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useFormContext } from 'react-hook-form';
// Component Libs
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// Constants
import { TOKEN } from '../../../../helpers/constants';
// Contexts
import { SettingsContext } from '../../../../helpers/context/settingsContext';
import { SchemaContext } from '../../../../helpers/context/schemaContext';
import { LoadingContext } from '../../../../helpers/context/loading';

const Tickets = () => {
  const [settings, setSettings] = useContext(SettingsContext);
  const [schemas, setSchemas] = useContext(SchemaContext);
  const [template, setTemplate] = useState({});
  const [loading, setLoading] = useContext(LoadingContext);
  const { register, setValue, getValues } = useFormContext();

  useEffect(() => {
    /**
     * takes in input the rearranged data and sets
     * it in the RHF register
     * */
    const setDefaultValues = (groupedData) => {
      setValue(
        'tickets.readFromTwilio',
        settings?.properties?.tickets?.readFromTwilio
      );
      for (let i = 0; i < groupedData.length; i++) {
        let allSelected = 0;
        let section = groupedData[i];

        for (let i = 0; i < section.length; i++) {
          setValue(
            `tickets.${section[i]?.groupName}.${section[i].name}`,
            settings?.properties?.tickets?.properties.includes(section[i].name)
          );
          if (
            settings?.properties?.tickets?.properties.includes(section[i].name)
          ) {
            allSelected++;
          }
        }
        if (allSelected === section.length) {
          setValue(`tickets.${section[0]?.groupName}.total`, true);
        }
      }
    };
    setLoading(true);
    if (Object.keys(schemas).length !== 0) {
      setTemplate(schemas.tickets);
      setDefaultValues(Object.values(schemas.tickets));
    }
  }, [settings, schemas, setValue]);

  const CheckDropdownItem = ({ id, label, key }) => {
    return (
      <Form.Group className="dropdown-item mb-0">
        <Form.Check type="checkbox" key={key} label={label} {...register(id)} />
      </Form.Group>
    );
  };

  return (
    <Form.Group>
      <Container className="view">
        <Row>
          <Col>
            <div className="side-col">
              <p className="side-title">View Ticket properties in Twilio</p>
              <Form.Check
                className="side-switch"
                type="switch"
                id="custom-switch"
                key="tickets.readFromTwilio"
                {...register('tickets.readFromTwilio')}
              />
            </div>
            <p className="side-text">
              Scegli quali properties dei tuoi contatti Hubspot vuoi avere in
              Twilio Flex.
            </p>
          </Col>
          {getValues('tickets.readFromTwilio') && (
            <Col>
              {Object.values(template).map((group, outerIndex) => (
                <Container key={outerIndex}>
                  <Col>
                    <Dropdown>
                      <Dropdown.Toggle
                        id={`dropdown-basic-${group[0].groupName}`}
                      >
                        <Form.Check
                          type="checkbox"
                          label={group[0]?.groupName}
                          {...register(`tickets.${group[0]?.groupName}.total`)}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {group.map((i, innerIndex) => (
                          <Dropdown.Item
                            as={CheckDropdownItem}
                            id={`tickets.${i?.groupName}.${i?.name}`}
                            key={innerIndex}
                            label={i?.label}
                          >
                            {i?.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Container>
              ))}
            </Col>
          )}
        </Row>
      </Container>
    </Form.Group>
  );
};

export default Tickets;
