/**
 * takes in input the raw data that the API fetches
 * and rearranges it keying it with the field groupname
 * */
export const dataModeler = (unorganizedData) => {
  const organizesData = {};
  unorganizedData.forEach((item) => {
    if (!organizesData[item.groupName]) {
      organizesData[item.groupName] = [];
    }
    organizesData[item.groupName].push(item);
  });
  return organizesData;
};
