import React, { useState, useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import FormControl from 'react-bootstrap/FormControl';

import SearchBox from '../../../../components/SearchBox';
import { SettingsContext } from '../../../../helpers/context/settingsContext';

const PropsForm = ({ form, setForm }) => {
  const [settings, setSettings] = useContext(SettingsContext);

  const [subFormType, setSubFormType] = useState('');
  const [isField, setIsField] = useState();
  const [ipc, setIpc] = useState({
    equal: '',
    different: '',
  });
  const { register, setValue, getValues } = useFormContext();
  const [badges, setBadges] = useState({
    equal: [],
    different: [],
  });

  function splitByComma(string) {
    return string.split(', ');
  }

  function joinWithComma(arr) {
    return arr.join(', ');
  }

  const setDefaultCreateValues = () => {
    setValue(`edProp.${form.index}.index`, form.index);
    setValue(`edProp.${form.index}.object`, '');
    setValue(`edProp.${form.index}.groupName`, '');
    setValue(`edProp.${form.index}.property`, '');
    setValue(`edProp.${form.index}.condition`, '');
    setValue(`edProp.${form.index}.value`, '');
    setValue(`edProp.${form.index}.prop_value`, '');
    setValue(`edProp.${form.index}.groupNameEnable`, '');
    setValue(`edProp.${form.index}.enable`, '');
  };

  useEffect(() => {
    if (form.type === 'create') {
      setDefaultCreateValues();
    }
  }, [form]);

  const deleteBadge = (badge, type) => {
    if (type === 'EQUAL') {
      const updatedBadges = badges.equal.filter((item) => {
        return item !== badge;
      });
      setBadges((state) => ({ ...state, equal: [...updatedBadges] }));
    }
    if (type === 'DIFFERENT') {
      const updatedBadges = badges.different.filter((item) => {
        return item !== badge;
      });
      setBadges((state) => ({ ...state, different: [...updatedBadges] }));
    }
  };

  const addBadge = (event, type) => {
    if (type === 'EQUAL') {
      setBadges((state) => ({
        ...state,
        equal: [...state.equal, event.target.value],
      }));
      setIpc((state) => ({ ...state, equal: '' }));
    }
    if (type === 'DIFFERENT') {
      setBadges((state) => ({
        ...state,
        different: [...state.different, event.target.value],
      }));
      setIpc((state) => ({ ...state, different: '' }));
    }
  };

  useEffect(() => {
    if (isField === 'EQUAL') {
      const badgesString = joinWithComma(badges.equal);
      setValue(`edProp.${form.index}.condition`, `${isField.toLowerCase()} to`);
      setValue(
        `edProp.${form.index}.prop_value`,
        `${isField.toLowerCase()} to ${badgesString}`
      );
    }
    if (isField === 'DIFFERENT') {
      const badgesString = joinWithComma(badges.different);
      setValue(`edProp.${form.index}.condition`, `${isField.toLowerCase()} to`);
      setValue(
        `edProp.${form.index}.prop_value`,
        `${isField.toLowerCase()} to ${badgesString}`
      );
    }
    if (isField === 'TRUE') {
      setValue(`edProp.${form.index}.condition`, `${isField.toLowerCase()}`);
      setValue(`edProp.${form.index}.prop_value`, isField.toLowerCase());
    }
    if (isField === 'EMPTY') {
      setValue(`edProp.${form.index}.condition`, `${isField.toLowerCase()}`);
      setValue(`edProp.${form.index}.prop_value`, isField.toLowerCase());
    }
  }, [isField, badges]);

  useEffect(() => {
    setBadges({
      equal: [],
      different: [],
    });
    setIpc({
      equal: '',
      different: '',
    });
  }, [isField]);

  const submit = () => {
    if (form.type === 'create') {
      setSettings((state) => ({
        ...state,
        editableProperties: [
          ...state.editableProperties,
          {
            object: getValues(`edProp.${form.index}.object`),
            property: getValues(`edProp.${form.index}.property`),
            condition: getValues(`edProp.${form.index}.condition`),
            value:
              getValues(`edProp.${form.index}.condition`) === 'equal to'
                ? badges.equal
                : getValues(`edProp.${form.index}.condition`) === 'different to'
                ? badges.different
                : [],
            enable: getValues(`edProp.${form.index}.enable`),
          },
        ],
      }));
    }
    if (form.type === 'modify') {
      const editableProperties = [];
      for (let index = 0; index < settings.editableProperties.length; index++) {
        const prop = {
          object: getValues(`edProp.${index}.object`),
          property: getValues(`edProp.${index}.property`),
          condition: getValues(`edProp.${index}.condition`),
          value:
            getValues(`edProp.${form.index}.condition`) === 'equal to'
              ? badges.equal
              : getValues(`edProp.${form.index}.condition`) === 'different to'
              ? badges.different
              : [],
          enable: getValues(`edProp.${index}.enable`),
        };
        editableProperties.push(prop);
      }
      setSettings((state) => ({
        ...state,
        editableProperties: editableProperties,
      }));
    }
    setForm({
      visible: false,
      index: '',
      type: 'modify',
    });
  };
  return (
    <div className="view">
      <div className="editable-section">
        <div className="editable-prop editable-prop-banner">
          <p className="prop-title">Editable property group</p>
          <div className="prop-buttons-section">
            <Button
              className="custom-button float-right"
              onClick={() => submit()}
            >
              Save condition
            </Button>
          </div>
        </div>
        <div className="editable-prop editable-prop-edit">
          <Col className="edit-col">
            <div className="edit-input-group">
              <p>If</p>
              <FormControl
                type="text"
                placeholder="Choose properties"
                className="edit-input-disabled"
                onClick={() => setSubFormType('IF')}
                {...register(`edProp.${form.index}.property`)}
              />
            </div>
            <div className="edit-input-group">
              <p>Is</p>
              <FormControl
                type="text"
                placeholder="Choose condition"
                className="edit-input-disabled"
                onClick={() => setSubFormType('IS')}
                {...register(`edProp.${form.index}.condition`)}
              />
            </div>
            <div className="edit-input-group">
              <p>Enable</p>
              <FormControl
                type="text"
                placeholder="Choose enable properties"
                className="edit-input-disabled"
                onClick={() => setSubFormType('ENABLE')}
                {...register(`edProp.${form.index}.enable`)}
              />
            </div>
          </Col>
          {subFormType === 'IF' && (
            <Col className="edit-col-right">
              <div className="edit-input-group-right">
                <SearchBox form={form} type="IF" />
              </div>
            </Col>
          )}
          {subFormType === 'IS' && (
            <Col className="edit-col-right">
              <div className="edit-input-group-right">
                <Form.Check
                  type="radio"
                  label="equal to"
                  id="EQUAL"
                  name="edit-radios"
                  className="radios"
                  onClick={() => setIsField('EQUAL')}
                />
                {isField === 'EQUAL' && (
                  <div className="editable-settings-container ">
                    <FormControl
                      type="text"
                      className="edit-input"
                      value={ipc.equal}
                      onChange={(e) =>
                        setIpc((state) => ({ ...state, equal: e.target.value }))
                      }
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          addBadge(event, 'EQUAL');
                        }
                      }}
                    />
                    <div className="badges-box">
                      {badges.equal.length !== 0 &&
                        badges.equal.map((badge) => (
                          <Badge
                            className="badge"
                            key={badge}
                            onClick={() => deleteBadge(badge, 'EQUAL')}
                          >
                            <p>{badge}</p>
                            <p className="delete-badge">x</p>
                          </Badge>
                        ))}
                    </div>
                  </div>
                )}
                <Form.Check
                  type="radio"
                  label="different to"
                  id="DIFFERENT"
                  name="edit-radios"
                  className="radios"
                  onClick={() => setIsField('DIFFERENT')}
                />
                {isField === 'DIFFERENT' && (
                  <div className="editable-settings-container ">
                    <FormControl
                      type="text"
                      className="edit-input"
                      value={ipc.different}
                      onChange={(e) =>
                        setIpc((state) => ({
                          ...state,
                          different: e.target.value,
                        }))
                      }
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          addBadge(event, 'DIFFERENT');
                        }
                      }}
                    />
                    <div className="badges-box">
                      {badges.different.length !== 0 &&
                        badges.different.map((badge) => (
                          <Badge
                            className="badge"
                            key={badge}
                            onClick={() => deleteBadge(badge, 'DIFFERENT')}
                          >
                            <p>{badge}</p>
                            <p className="delete-badge">x</p>
                          </Badge>
                        ))}
                    </div>
                  </div>
                )}
                <Form.Check
                  type="radio"
                  label="empty"
                  id="EMPTY"
                  name="edit-radios"
                  className="radios"
                  onClick={() => setIsField('EMPTY')}
                />
                <Form.Check
                  type="radio"
                  label="true"
                  id="TRUE"
                  name="edit-radios"
                  className="radios"
                  onClick={() => setIsField('TRUE')}
                />
              </div>
            </Col>
          )}
          {subFormType === 'ENABLE' && (
            <Col className="edit-col-right">
              <div className="edit-input-group-right">
                <SearchBox form={form} type="ENABLE" />
              </div>
            </Col>
          )}
        </div>
      </div>
    </div>
  );
};

export default PropsForm;
